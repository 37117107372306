<template>
  <div class="content-wrapper">
    <bo-page-title></bo-page-title>
    <div class="content pt-0">
      <div class="card">
        <div class="bg-white card-header">
          <div class="row align-items-center">
            <div class="col-md-4 col-lg-5">
              <div class="form-row">
                <div class="col-lg-4"> <h5 class="card-title font-weight-semibold">Daftar Pasien</h5></div>
                <div class="col-lg-8">
                    <div class="form-group mb-0 d-flex">
                        <date-range-picker
                            ref="picker"
                            :locale-data="datePickerConfig.locale"
                            :autoApply="datePickerConfig.autoApply"
                            v-model="dateRange"
                            :opens="'right'"
                            :ranges="datePickerConfig.ranges"
                            @update="updateValues"
                        >
                            <template v-slot:input="picker">
                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                            </template>
                        </date-range-picker>
                        <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-header">
          <div class="row">
            <div class="col-md-8 col-lg-9">
                <div class="row g-1">
                    <div class="col-md-auto">
                      <div class="d-flex flex-column flex-lg-row flex-nowrap">
                        <div class="mb-1 mb-lg-0 mr-lg-1">
                            <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                        </div>
                      </div>
                    </div>
                    <div class="col-md">
                      <div class="row g-1">
                        <div class="col-md-4">
                            <b-form-group class="mb-0">
                                <v-select placeholder="Pilih Status" @input="doFill" v-model="filter.status_ss" :options="mrStatus" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            </b-form-group>
                        </div>

                        
                      </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-lg-3">
                <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                    <input class="form-control"
                        v-model="filter.search"
                        @input="doSearch"
                        placeholder="Ketik Nama/No. RM Pasien"
                    />
                    <div class="form-control-feedback">
                        <i class="icon-search4 text-indigo"></i>
                    </div>
                        
                    <b-button
                        class="ml-1 d-inline-flex align-items-center"
                        variant="outline-success"
                        id="resetBtn"
                        @click="doResetData()"
                    >Reset</b-button>
                </div>
                
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-md-2">
              <p class="font-weight-bold mt-2">{{totalSelected}} : Item dipilih</p>
            </div>
            <div class="col-md-3">
              <b-button v-if="!totalSelected"
                  class="ml-1 d-inline-flex align-items-center"
                  variant="secondary"
              >Kirimkan Data</b-button>
              
              <b-button v-else
                  @click="kirimkanData"
                  class="ml-1 d-inline-flex align-items-center"
                  variant="success"
              >Kirimkan Data</b-button>
            </div>
          </div>
          <div class="table-responsive">
            <table
              class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
              <thead>
                <tr>
                  <th>NO.</th>
                  <th>
                    <b-form-checkbox
                      v-model="checkedAll"
                      :name="'checkedAll'"
                      @change="inputCheckedAll($event)"
                    >
                    </b-form-checkbox></th>
                  <th>NO. KEDATANGAN</th>
                  <th>TANGGAL MASUK</th>
                  <th>NAMA/NO KTP</th>
                  <th>KAMAR</th>
                  <th>DIAGNOSA</th>
                  <th>DOKTER</th>                                
                  <th>NO RM</th>
                  <th>CARA BAYAR</th>
                  <th>TANGGAL LAHIR</th>
                  <th>USIA</th>
                  <th>STATUS</th>
                  <th>STATUS SATU SEHAT</th>
                  <th>AKSI</th>
                </tr>
              </thead>
              <tbody v-if="(dataList||[]).length">
                <template v-for="(v,k) in (dataList||[])">
                  <tr :key="k" :class="!v.ssed_encounter_status ? 'table-danger':''">
                    <td>{{(data.per_page*(data.current_page-1))+k+1}}</td>
                    <td>
                      <b-form-checkbox v-if="!v.ssed_encounter_id && v.aranr_dokter_id"
                        v-model="v.checked"
                        :name="v.aranr_id+'k'"
                        @change="inputChecked($event,v.aranr_id,k)"
                      >
                      </b-form-checkbox>
                    </td>
                    <td>{{v.aranr_reg_code||"-"}}</td>
                    <td>{{v.aranr_reg_date | moment("DD MMMM YYYY, HH:mm")}}</td>
                    <td>
                      <span>{{uppercaseWord(v.ap_fullname)||"-"}}</span>
                      <p>{{v.ap_nik||"-"}}</p>
                    </td>
                    <td>
                      <template v-if="v.mranb_name && v.mrank_name">
                          <div class="font-weight-semibold">{{v.mranb_name || "-"}}</div>
                          <span class="text-secondary">{{v.mrank_name || "-"}}</span>
                          <span class="text-secondary" v-if="v.mranbed_name"> - {{v.mranbed_name || "-"}}</span>
                      </template>
                      <span v-else> - </span>
                    </td>
                    <td>
                      <a href="javascript:;" @click="lihatDiagnosa(v,k)" v-b-tooltip.click.html="v.diagnosa||'...'"
                      class="font-weight-semibold border-bottom"> Lihat Diagnosa </a>
                    </td>
                    <td>{{v.bu_full_name||"-"}}</td>
                    <td>{{v.ap_code||"-"}}</td>
                    <td>{{v.mcp_name||"-"}}</td>
                    <td>
                      <span v-if="v.ap_dob">{{v.ap_dob | moment("DD MMMM YYYY")}}</span>
                      <span v-else> - </span>
                    </td>
                    <td>{{v.ap_usia_with_ket||"-"}}</td>
                    <td>
                      <template>
                          <span :class="`badge ${v.arans_color}`">{{uppercaseWord(v.arans_name)||'-'}}</span>     
                      </template>
                    </td>
                    <td>
                      <span v-if="v.ssed_encounter_status == 'CREATED'" :class="`badge bg-info`">Terkirim</span>
                      <span v-else-if="v.ssed_encounter_status == 'INPROGRESS'" :class="`badge bg-primary`">Proses Verifikasi</span>  
                      <span v-else-if="v.ssed_encounter_status == 'INSERT_DIAGNOSA'" :class="`badge bg-info`">Verifikasi Diagnosa</span>   
                      <span v-else-if="v.ssed_encounter_status == 'PROCESSED_DIAGNOSA'" :class="`badge bg-info`">Verifikasi Diagnosa</span>   
                      <span v-else-if="v.ssed_encounter_status == 'HAS_DIAGNOSA'" :class="`badge bg-info`">Verifikasi Diagnosa</span>  
                      <span v-else-if="v.ssed_encounter_status == 'FINISHED'" :class="`badge bg-success`">Selesai</span>     
                      <span v-else :class="`badge bg-warning`">Belum Dikirim</span> 

                      <span v-if="v.bu_satu_sehat_id" :class="`badge bg-success mt-1`">NIK Dokter Telah Terverifikasi</span>
                      <span v-else :class="`badge bg-warning mt-1`">NIK Dokter Belum Terverifikasi</span>
                      
                      <span v-if="v.ap_satu_sehat_id" :class="`badge bg-success mt-1`">NIK Pasien Telah Terverifikasi</span>
                      <span v-else :class="`badge bg-warning mt-1`">NIK Pasien Belum Terverifikasi</span>

                      <span class="text-danger mt-1 d-block" v-if="v.ssed_pasien_fail_reason">{{v.ssed_pasien_fail_reason||"-"}}</span>
                      <span class="text-danger mt-1 d-block" v-if="v.ssed_dokter_fail_reason">{{v.ssed_dokter_fail_reason||"-"}}</span>
                      <span class="text-danger mt-1 d-block" v-if="v.ssed_location_fail_reason">{{v.ssed_location_fail_reason||"-"}}</span>


                      <span class="text-danger mt-1 d-block" v-if="v.ssed_encounter_created_fail_reason"> Data Gagal Terkirim : {{v.ssed_encounter_created_fail_reason||"-"}}</span>

                      <span class="text-danger mt-1 d-block" v-if="v.ssed_encounter_finished_status == 'FAIL'"> Data Gagal Terkirim : 
                      <span v-if="v.ssed_encounter_finished_fail_reason == 'Element not found: Encounter.diagnosis (RuleNumber: 10457)'">Data Diagnosa Belum Diisi</span>
                      </span>   

                    </td>
                    <td>
                      <a href="javascript:;" @click="openRiwayatRMRanap(v)" class="btn btn-icon rounded-round btn-sm mr-1 alpha-info border-info"
                      data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="Lihat Riwayat Rekam Medis"><i class="icon-file-text3"></i></a>


                      <a href="javascript:;" v-if="!v.ssed_encounter_id && v.aranr_dokter_id" @click="kirimkanDataSingle(v,k)" class="btn btn-icon rounded-round btn-sm mr-1 mt-1 alpha-success border-success"
                      data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" :title="v.ssed_id ? 'Kirimkan Ulang Data Ke Satu Sehat' : 'Kirimkan Data Ke Satu Sehat'"><i class="icon-paperplane"></i></a>

                      <a v-if="v.ssed_encounter_finished_status == 'FAIL'" href="javascript:;" @click="kirimUlangDiagnosa(k,v)" class="btn btn-icon rounded-round btn-sm mr-1 alpha-warning border-warning"
                      data-toggle="tooltip" v-b-tooltip.hover.right data-placement="top" title="Kirim Ulang Diagnosa"><i class="icon-paperplane"></i></a>
                      
                    </td>
                  </tr>
                </template>
              </tbody>
              <tbody v-if="data.data.length == 0">
                  <tr>
                      <td colspan="99">
                      <div class="text-center">
                          <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                          <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                      </div>
                      </td>
                  </tr>
              </tbody>
              <tbody v-if="!data.data">   
                  <tr>
                      <td colspan="99"><div class="skeletal-comp"></div></td>
                  </tr>
                  <tr>
                      <td colspan="99"><div class="skeletal-comp"></div></td>
                  </tr>
                  <tr>
                      <td colspan="99"><div class="skeletal-comp"></div></td>
                  </tr>
              </tbody>
            </table>
        
            <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">     <b-pagination
                class="mb-0"
                v-model="pageNo"
                :per-page="data.per_page"
                :total-rows="data.total"
            />
            </b-card-footer>

            <div :class="(dataList||[]).length && !loadingTable && dataList ? 'table-scroll-actions' : 'table-scroll-actions d-none'">
              <a href="javascript:;" data-scroll="left" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
              <i class="icon-chevron-left"></i>
              </a>
              <a href="javascript:;" data-scroll="right" class="btn btn-icon alpha-info border-info text-info-800 rounded-round">
              <i class="icon-chevron-right"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="modal.riwayatRM" title="Riwayat Rekam Medis" size="xl" ok-only ok-title="Tutup">
      <riwayat-rm-ranap :reg-id.sync="dataRanap.regId" :row-data="dataRanap" />
    </b-modal>

    <b-modal v-model="selectedModal" @ok.prevent="kirimBulkData()" ok-title="Kirimkan Data" title="Detail Data Yang Dikirimkan Ke SatuSehat" size="lg">
      <div class="row">
        <div class="col-md-12">
          <table class="table table-bordered table-striped table-hover table-sm patient-table text-uppercase table-sm">
            <thead>
              <tr>
                <th>NO. </th>
                <th>NO. KEDATANGAN.</th>
                <th>TANGGAL MASUK</th>
                <th>NAMA</th>
                <th>NO RM</th>
                <th>DOKTER</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(v,k) in (viewSelected||[])" :key="k">
                <td>{{k+1}}</td>
                <td>{{v.aranr_reg_code||"-"}}</td>
                <td>{{v.aranr_reg_date||"-"}}</td>
                <td>{{v.ap_fullname||"-"}}</td>
                <td>{{v.ap_code||"-"}}</td>
                <td>{{v.bu_full_name||"-"}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
  </div>
</template>


<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
const moment = require('moment')
import _ from 'lodash'
import $ from 'jquery'
import { default as RiwayatRmRanap } from '@/components/RiwayatRanap/RiwayatRM.vue'




export default {
  extends: GlobalVue,
  data() {
    return {
      datePickerConfig: {
        startDate: new Date(),
        endDate: new Date(),
        autoApply: true,
        ranges: {
            'Hari Ini': [new Date(), new Date()],
            '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
            '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
        },
        applyClass: 'btn-sm btn-primary',
        cancelClass: 'btn-sm btn-light',
        locale: {
            applyLabel: 'Terapkan',
            cancelLabel: 'Batal',
            direction: 'ltr',
            format: 'mm/dd/yyyy',
            separator: ' - ',
        }
      },
      rowEdit: {},
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      
      mrStatus: [
        {
          text : "Belum Dikirim",
          value : "NOT_YET" 
        },
        {
          text : "Terkirim",
          value : "CREATED" 
        },
        {
          text : "Proses Verifikasi",
          value : "INPROGRESS" 
        },
        {
          text : "Verifikasi Diagnosa",
          value : "DIAGNOSA" 
        },
        {
          text : "Selesai",
          value : "FINISHED" 
        },
      ],

      selectedArId: [],
      checkedAll: false,
      viewSelected: [],
      selectedModal: false,
      
      modal: {
        kajianAwal: false,
        edukasiTerintegrasi: false,
        hais: false,
        dokumenPersetujuan: false,
      },
      tabKajianAwal: 0,
      kajianData: {},
      kajianPersalinanData: {},
      dataRanap: {},

    }
  },
  computed: {
    totalSelected(){
      return (this.selectedArId||[]).length
    }
  },
  components:{DateRangePicker,RiwayatRmRanap},
  mounted(){
    this.filter.status_ss = 'NOT_YET'
    
    this.doConvertDate()      
    this.apiGet()
    
    setTimeout(()=>{
      this.setSlide()
    },1000)
  },
  methods: {
    kirimkanData(){
      if(this.totalSelected > 20){
        return this.$swal({
          title: `Tidak dapat lanjut`,
          text: `Data maksimal yang dapat di verifikasi adalah 20 data`,
          icon: 'error',
        })
      } 

      this.loadingOverlay = true
      Gen.apiRest(
          "/do/"+this.modulePage,
          {data:{type:'view-bulk', dataPost: this.selectedArId}}, 
          "POST"
      ).then(res=>{
        let resp = res.data
        this.selectedModal = true    
        this.loadingOverlay = false
        this.viewSelected = resp.viewSelected
      })
    },
    apiGet(params = {}, withLoad = false){
      
      this.loadingOverlay = true
      this.data.data = false
      let slugs = (this.pageSlug?"/"+this.pageSlug:"")+(this.pageId?"/"+this.pageId:"")
      
      if(params.slugs) slugs = params.slugs
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      
      Gen.apiRest(
        "/get/"+this.modulePage+
        slugs, 
        {
          params: Object.assign({}, paramsQuery, params.query||{})
        }
      ).then(res=>{
        this.loadingOverlay = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })

        // set checked
        let total = 0
        for(let i = 0; i < (this.data.data||[]).length; i++){
          let index = (this.selectedArId||[]).findIndex(x => x == this.data.data[i]['aranr_id'])
          this.data.data[i]['checked'] = (index != -1) ? true : false
          if(this.data.data[i]['checked']){
            total = total + 1
          }
        }
        this.checkedAll = (total == (this.data.data||[]).length) && total != 0
        
      }).catch(()=>{
        this.loadingOverlay = false
      })
    },
    inputCheckedAll(e){
      if(e){
        if(this.totalSelected >= 20){
          this.checkedAll = false
          return this.$swal({
            title: `Tidak dapat lanjut`,
            text: `Data maksimal yang dapat di verifikasi adalah 20 data`,
            icon: 'error',
          })
        }
        for(let i = 0; i < (this.data.data||[]).length; i++){
          if(!this.data.data[i]['checked'] && !this.data.data[i]['ssed_encounter_id'] && this.data.data[i].aranr_dokter_id){
            this.data.data[i]['checked'] = true
            this.selectedArId.push(this.data.data[i]['aranr_id'])
          }
        }
      }else{
        for(let i = 0; i < (this.data.data||[]).length; i++){
          this.data.data[i]['checked'] = false
          
          let index = (this.selectedArId||[]).findIndex(x => x == this.data.data[i]['aranr_id'])        
          if(index != -1){
            this.selectedArId.splice(index,1)
          }
        }
      }
      console.log(this.selectedArId)
    },
    inputChecked(e,regId,k){
      if(e){
        if(this.totalSelected >= 20){
          this.data.data[k].checked = false
          return this.$swal({
            title: `Tidak dapat lanjut`,
            text: `Data maksimal yang dapat di verifikasi adalah 20 data`,
            icon: 'error',
          })
        }
        this.selectedArId.push(regId)
      }else{
        let index = (this.selectedArId||[]).findIndex(x => x == regId)        
        if(index != -1){
          this.selectedArId.splice(index,1)
        }
      }
    
      let total = 0
      for(let i = 0; i < (this.data.data||[]).length; i++){
        let index = (this.selectedArId||[]).findIndex(x => x == this.data.data[i]['aranr_id'])
        if(this.data.data[i]['checked']){
          total = total + 1
        }
      }
      this.checkedAll = (total == (this.data.data||[]).length) && total != 0
    },

    openRiwayatRMRanap(data){
      this.$set(this, 'dataRanap', { ...data, regId: data.aranr_id })
      this.$set(this.modal, 'riwayatRM', true)
    },
    
    setSlide(){
      const responsiveTable = document.querySelector('.table-responsive')
      if(responsiveTable){
        const responsiveTableScroll = responsiveTable.querySelector('.table-scroll-actions')
        const responsiveTableScrollActions = responsiveTable.querySelectorAll('.btn')
        if(responsiveTableScroll){
            // responsiveTableScroll.style.top = (document.documentElement.scrollTop + (window.innerHeight / 1.5) - 32)+'px'
            Array.from(responsiveTableScrollActions).forEach(action => {
                action.addEventListener('click', e => {
                    const scrollAmount = action.dataset.scroll == 'right' ? responsiveTable.scrollLeft + 100 : responsiveTable.scrollLeft - 100
                      
                    responsiveTable.scroll({
                        left: scrollAmount,
                        behavior: 'smooth'
                    })
                })
            })
        }
      }
    },
    doResetData(){
      this.doReset()
      this.dateRange =  {
          startDate: new Date(),
          endDate: new Date(),
      }
      this.doConvertDate()
    },
    doRefreshData(){
      this.apiGet()
      this.selectedArId = []
      this.filter.status_ss = 'NOT_YET'
      window.scrollTo(0,0)
    },
    doConvertDate(){
      this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
    },
    doPageOne(){
      this.filter.page = 1
    },
    doFill(){
      this.doPageOne()
      this.doFilter()
    },
    updateValues(e){
      this.doConvertDate()
      this.doFill()
    },
    doSearch: _.debounce(function(){
      this.doPageOne()
      this.doFilter()
    },500),
    getConfigDynamic(master,value){
      let text = ''
      if(value){
          let index = (master||[]).findIndex(x => x.value == value)
          if(index !== -1){
              text = master[index]['text']
          }
      }
      return text
    },

    lihatDiagnosa(v,k){
        Gen.apiRest(
            "/do/"+'RanapRekonsiliasi',
            {data:{type:'lihat-diagnosa', id: v.aranr_id}}, 
            "POST"
        ).then(res=>{
          this.data.data[k]['diagnosa'] = res.data        
        })
    },


    kirimBulkData(){
      this.$swal({
        title: `Kirimkan data ke satu sehat?`,
        text: `Pastikan data yang dikirimkan telah benar`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Ya`,
        cancelButtonText:  `Tidak, kembali`,        
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if(result.value){
          let dataPost = []
          _.forEach(this.viewSelected, (v, k) => {
            dataPost.push({
              aranr_id : v.aranr_id,
              ap_id: v.ap_id,
              ps_id: v.ap_satu_sehat_id,
              p_nik: v.ap_nik,
              dokter_id: v.aranr_dokter_id,
              ds_id: v.bu_satu_sehat_id,
              dokter_nik: v.bu_nik,
              fullname: v.ap_fullname,
              dokter_name: v.bu_full_name,
              reg_date: moment(v.aranr_reg_date).format('YYYY-MM-DD'),
              reg_date_full: v.aranr_reg_date,
              reg_code: v.aranr_reg_code,
              kamar_id: v.aranres_kamar,

              ssed_encounter_created_status: v.ssed_encounter_created_status,
              ssed_encounter_status: v.ssed_encounter_status,
              ssed_reg_code: v.ssed_reg_code,
              ssed_encounter_id: v.ssed_encounter_id,
              id: v.ssed_id
            })
          })
            
          this.$swal.fire({
            title: 'Processing...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
              "/do/"+this.modulePage,
              {data:{type:'post-data-ranap', dataPost: dataPost}}, 
              "POST"
          ).then(res=>{
            this.$swal.close()
            let resp = res.data
            this.$swal({
              title: resp.message,
              icon: resp.status,
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              if (result.value) {
                this.apiGet()
                this.selectedModal = false
                this.selectedArId = []
              }
            })          
          }).catch(err => {
  					this.$swal.close()
            if (err) {
              err.statusType = err.status
              err.status = "error"
              err.title = err.response?.data?.title
              err.message = err.response?.data?.message
              err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })
        }
      })
    },

    kirimUlangDiagnosa(k,v){
      this.$swal({
        title: `Kirimkan ulang data ke satu sehat?`,
        text: `Pastikan data yang dikirimkan telah diisi kembali diagnosa`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Ya`,
        cancelButtonText:  `Tidak, kembali`,        
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if(result.value){
          this.$swal.fire({
            title: 'Processing...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
              "/do/"+this.modulePage,
              {data:{type:'post-ulang-data-diagnosa', id: v.ssed_id}}, 
              "POST"
          ).then(res=>{
            let resp = res.data
  					this.$swal.close()              
            this.$swal({
              title: resp.message,
              icon: resp.status,
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              if (result.value) {
                this.apiGet()
              }
            })          
          }).catch(err => {
  					this.$swal.close()            
            if (err) {
              err.statusType = err.status
              err.status = "error"
              err.title = err.response?.data?.title
              err.message = err.response?.data?.message
              err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })
        }
      })
    },

    kirimkanDataSingle(v,k){
      this.$swal({
        title: `Kirimkan data ke satu sehat?`,
        text: `Pastikan data yang dikirimkan telah benar`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Ya`,
        cancelButtonText:  `Tidak, kembali`,        
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then(result => {
        if(result.value){
          let dataPost = [
            {
              aranr_id : v.aranr_id,
              ap_id: v.ap_id,
              ps_id: v.ap_satu_sehat_id,
              p_nik: v.ap_nik,
              dokter_id: v.aranr_dokter_id,
              ds_id: v.bu_satu_sehat_id,
              dokter_nik: v.bu_nik,
              fullname: v.ap_fullname,
              dokter_name: v.bu_full_name,
              reg_date: moment(v.aranr_reg_date).format('YYYY-MM-DD'),
              reg_date_full: v.aranr_reg_date,
              reg_code: v.aranr_reg_code,
              kamar_id: v.aranres_kamar,

              ssed_encounter_created_status: v.ssed_encounter_created_status,
              ssed_encounter_status: v.ssed_encounter_status,
              ssed_reg_code: v.ssed_reg_code,
              ssed_encounter_id: v.ssed_encounter_id,
              id: v.ssed_id
            }
          ]
          
          this.$swal.fire({
            title: 'Processing...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest(
              "/do/"+this.modulePage,
              {data:{type:'post-data-ranap', dataPost: dataPost}}, 
              "POST"
          ).then(res=>{
            let resp = res.data
  					this.$swal.close()              
            this.$swal({
              title: resp.message,
              icon: resp.status,
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              if (result.value) {
                this.apiGet()
              }
            })          
          }).catch(err => {
  					this.$swal.close()            
            if (err) {
              err.statusType = err.status
              err.status = "error"
              err.title = err.response?.data?.title
              err.message = err.response?.data?.message
              err.messageError = err.message
            }
            this.doSetAlertForm(err)
          })
        }
      })
    }
  },
  filters: {
    date(val) {
      return val ? moment(val).format("D MMM YYYY") : ""
    }
  },
  watch:{
    $route(){
      this.apiGet()
      setTimeout(()=>{
        if(this.isList){
            this.setSlide()
        }
      },1000)
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }

}
</script>